import React from 'react'; // eslint-disable-line no-unused-vars

import Item from '../Item';
import Renderer from '../Renderer';

export default class RowItem extends Item {

	getAdditionalClasses(values) {
		return [ 'RowItem' ];
	}

	performAction() {
		return;
	}

	renderItem(values) {
		return (<>
			{ Renderer.renderItems(this.props.config.items, this.props.site) }
		</>);
	}

}
