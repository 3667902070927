import React, { Component } from 'react'
import Util from './Util'

export default class Item extends Component {

	constructor(props) {
		super(props)
		this.state = {
			pressed: false
		}
		this.valueCache = null
	}

	_evaluteDynamicProperties(obj, ctx) {
		let missingData = false
		obj = Util.copyObject(obj)
		for(let k in obj) {
			if(typeof(obj[k]) === 'object') {
				let subObj = this._evaluteDynamicProperties(obj[k], ctx)
				obj[k] = subObj.obj
				if(obj.missingData){
					missingData = true
				}
			} else if(k.substr(-1) === '=') {
				let kk = k.substr(0, k.length - 1)
				if(kk === 'type')  {
					continue
				}
				let evalValue = this.props.site.evaluateExpression(obj[k], ctx, this)
				if(evalValue !== null) {
					obj[kk] = evalValue
					delete obj[k]
				} else {
					missingData = true
				}
			}
		}
		return { obj, missingData }
	}

	evaluteDynamicProperties(obj, ctx) {
		let ret = this._evaluteDynamicProperties(obj, ctx)
		this.setState({ loading: ret.missingData })
		return ret.obj
	}

	dataSourceUpdated() {
		this.valueCache = null
		this.forceUpdate()
	}

	get values() {
		if(this.valueCache === null) {
			this.valueCache = this.evaluteDynamicProperties(this.props.config)
		}
		return this.valueCache
	}

	onTouchStart(e) {
		if(this.busy) {
			return
		}
		this.setState({ pressed: true })
	}
	
	onTouchEnd(e) {
		if(this.busy) {
			return
		}
		if(this.state.pressed) {
			this.performAction()
		}
		this.setState({ pressed: false })
	}

	onTouchMove(e) {
		if(this.busy) {
			return
		}
		this.setState({ pressed: false })
	}

	onTouchCancel(e) {
		if(this.busy) {
			return
		}
		this.setState({ pressed: false })
	}

	_performActions(_actions) {

		let _performNextAction = (actions, performNextAction) => {
			let next = _ => performNextAction(actions, performNextAction)
			let a = actions.shift()
			console.log('PERFORMING', a)

			if(a === undefined) {
				console.log('ALL DONE!!!')
				this.setState({ performing: false })
			} else if(a.type === 'message-box') {
				alert(a.message)
				next()
			} else if(a.type === 'http-request') {
				this.props.site.runHTTPRequestWithConfig(a, _ => {
					next()
				})
			} else if(a.type === 'log') {
				console.log('*** Logging from component', a.log)
				next()
			} else if(a.type === 'reload-data-sources') {
				let ds = a['data-sources']
				if(typeof(ds) === 'boolean' || ds === undefined) {
					console.log('shoud-reload ALLL')
					this.props.site.loadDataSources(null, next)
				} else {
					console.log('shoud-reload', ds)
					this.props.site.loadDataSources(ds, next)
				}
			} else {
				console.log('*** Unknown action', a)
				next()
			}
		}

		_performNextAction(_actions, _performNextAction)		

	}

	get busy() {
		return (this.state.loading || this.state.performing)
	}

	performAction() {

		if(this.busy) {
			return
		}

		this.setState({ performing: true })

		// TODO: Heptic impact here

		if(this.props.onAction) {
			this.props.onAction()
		}

		let actions = []
		if(this.props.config.actions) {
			actions.push(...this.props.config.actions)
		} else if(this.props.config.action) {
			actions.push(this.props.config.action)
		}
		if(this.props.config.reloadDataSourcesOnComplete) {
			actions.push({
				'type': 'reload-data-sources',
				'data-sources': this.props.config.reloadDataSourcesOnComplete
			})
		}

		let ctx = { self: Util.copyObject(this.values) }
		let preparedActions = []
		for(let a of actions) {
			a = this.evaluteDynamicProperties(a, ctx)
			preparedActions.push(a)
		}
		this._performActions(preparedActions)

	}

	getAdditionalClasses(values) {
		return []
	}

	renderItem() {
		return <div/>
	}

	render() {
		let values = this.values

		let wrapper = {
			ref: 'wrapper',
			className: [ 'Item', ...this.getAdditionalClasses(values) ],
			onTouchStart: e => this.onTouchStart(e),
			onTouchEnd: e => this.onTouchEnd(e),
			onTouchMove: e => this.onTouchMove(e),
			onTouchCancel: e => this.onTouchCancel(e)
		}

		if(this.state.pressed) {
			wrapper.className.push('pressed')
		}

		if(this.busy) {
			wrapper.className.push('loading')
		}

		wrapper.className = wrapper.className.join(' ')

		return (
			<div { ...wrapper }>
				{ this.renderItem(values) }
			</div>
		)
	}

}
