
export default class Uniwrapper {

	static getInstance() {
		if(!window.uniwrapperInstance) {
			window.uniwrapperInstance = new Uniwrapper()
		}
		return window.uniwrapperInstance
	}

	static get isWrapped() {
		return (
			(window.webkit && window.webkit.messageHandlers) ||
			window.androidBridge
		)
	}

	constructor() {
		if(window.uniwrapperInstance) {
			console.log('Warning! DO NOT use Uniwrapper constructor! Use getInstance() instead!')
			return null
		}
		this.wrapperCallbackIds = (new Date()).getTime()
		this.wrapperCallbacks = {}
		window.wrapperMessageProcessor = (reponse) => { this.processWrapperMessage(reponse) }
	}

	initialRenderDone() {
		this.sendRequest('INITIAL_RENDER_DONE')
	}

	sendRequest(rq, args, callback) {
		this.wrapperCallbackIds++
		if(callback) {
			this.wrapperCallbacks[this.wrapperCallbackIds] = callback
		}
		if(typeof args != 'object') {
			args = {}
		}
		const msg = {
			callbackId: this.wrapperCallbackIds + '',
			rq: rq,
			args: args
		}
		console.log('* Uniwrapper:sendRequest', { rq, args, hasCallback: (!!callback), callbackId: this.wrapperCallbackIds })
		if(window.webkit && window.webkit.messageHandlers) {
			window.webkit.messageHandlers["uniwrapper_bridge"].postMessage(msg)
		} else if(typeof window.androidBridge !== "undefined" && window.androidBridge !== null) {
			window.androidBridge.postMessage(JSON.stringify(msg))
		}
	}
	
	processWrapperMessage(message) {
		console.log('* Uniwrapper:processWrapperMessage', message)
		if(message.rq == 'response') {
			if(message.callbackId && this.wrapperCallbacks[message.callbackId]) {
				this.wrapperCallbacks[message.callbackId](message.response)
				delete this.wrapperCallbacks[message.callbackId]
			}
		}
	}

}
