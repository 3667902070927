import React from 'react'

import API from '../api'
import Site from '../Site'
import ViewController from '../ViewController'
import SiteViewController from './SiteViewController'

export default class CloudLinkViewController extends ViewController {

	constructor(props) {
		super(props)
		this.navigationItem.title = 'Link Dashboard'
		this.state = { code: null }
	}

	componentDidMount() {
		API.shared.addEventListener(this, API.Events.NewLinkEstablished, () => {
			this.props.navigationController.popToRootView()
		})
		API.shared.addEventListener(this, API.Events.CloudConnectionStateChanged, () => {
			if(!API.shared.connected) {
				this.props.navigationController.popToRootView()
			}
		})
		API.shared.sendRequest('BEGIN-LINK', {}, response => {
			this.setState({ code: response.code })
		})
	}

	componentWillUnmount() {
		API.shared.removeEventListener(this)
		API.shared.sendRequest('STOP-LINK', {})
	}

	getAdditionalClasses() {
		return [ 'CloudLinkViewController' ]
	}

	renderView() {

		return (
			<>
				<div/>
				<div className="Middle">
					<div className="Info">
						Enter the following code in Buttons cloud
					</div>
					<div className="Code">
						{ this.state.code ? this.state.code : 'Waiting for code...' }
					</div>
				</div>
				<div/>
			</>
		)
	}

}
