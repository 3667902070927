import React from 'react';

import Renderer from '../Renderer';
import ViewController from '../ViewController';
import SiteOptionsViewController from './SiteOptionsViewController';

export default class SiteViewController extends ViewController {

	constructor(props) {
		super(props);

		this.site = props.site;
				
		this.navigationItem.title = this.site.config.title;
		this.navigationItem.rightButton = {
			icon: 'cog',
			onClick: _ => {
				this.props.navigationController.pushView(
					<SiteOptionsViewController site={ this.site }/>
				);
			}
		};

	}

	componentDidMount() {
		this.site.loadDataSources();
		this.visibilityCallback = _ => {
			if(document.visibilityState === 'hidden') {
				return;
			}
			this.site.loadDataSources();
		};
		document.addEventListener('visibilitychange', this.visibilityCallback);
	}

	componentWillUnmount() {
		this.site.deinitialize();
		document.removeEventListener('visibilitychange', this.visibilityCallback);
	}

	renderView() {
		return (
			<>
				<div className="Items">
					{ Renderer.renderItems(this.site.config.items, this.site) }
				</div>
			</>
		);
	}

}
