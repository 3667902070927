import React from 'react';
import Item from '../Item';

export default class SliderItem extends Item {

	onTouchStart(e) {
		super.onTouchStart(e);
		this.interacting = (e.target === this.refs.knob);
	}

	onTouchMove(e) {
		if(!this.interacting) {
			super.onTouchMove(e);
			return;
		}

		// TODO: cache sizes at render
		let width = this.refs.wrapper.getBoundingClientRect().width;
		let halfKnob = Math.abs(this.refs.knob.getBoundingClientRect().width / 2);

		let x = (e.touches[0].clientX - halfKnob);
		let percentage = (x / width);
		if(percentage < 0) {
			percentage = 0;
		} else if(percentage > 1) {
			percentage = 1;
		}
		
		this.valueCache.value = percentage;
		this.forceUpdate();
	}

	getAdditionalClasses(values) {
		return [ 'SliderItem' ];
	}

	renderItem(values) {
		return (<>
			<div className="Filler" style={ { width: (values.value * 100) + '%' } }/>
			<div className="KnobContainer">
				<div ref="knob" className="Knob" style={ { left: (values.value * 100) + '%' } }/>
			</div>
		</>);
	}

}
