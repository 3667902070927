import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import LibraryViewController from './ViewControllers/LibraryViewController'
import Site from './Site'

import API from './api'
import Uniwrapper from './External/uniwrapper'

import './Style/Layout.scss'
import './Style/Theme.scss'

const NAVIGATION_TRANSITION_TIME = 300

class AppWrap extends Component {

	constructor(props) {
		super(props)
		this.viewOperationsLocked = false
		this.viewControllerRefs = []
		this.state = { views: [] }
		console.log(API.shared)
	}

	componentDidMount() {		
		this.pushView(<LibraryViewController/>)
	}

	pushView(newView) {

		if(this.viewOperationsLocked) {
			return
		}

		this.viewOperationsLocked = true

		let viewId = "viewController_" + (this.state ? this.state.views.length : 0)
		let newRef = React.createRef()
		this.viewControllerRefs.push(newRef)
		newView = React.cloneElement(
			newView, 
			{ key: viewId, ref: newRef, navigationController: this }
		)

		if(this.state.views.length === 0) {

			this.setState(prevState => ({
				views: [ newView ]
			}), _ => {
				this.viewOperationsLocked = false
			})

		} else {

			this.setState(prevState => ({
				transition: 'pushing',
				views: [ ...prevState.views, newView ]
			}))

			setTimeout(() => {
				this.setState({ transition: null })
				this.viewOperationsLocked = false
			}, NAVIGATION_TRANSITION_TIME)

		}

	}

	popView(onComplete) {

		if(this.viewOperationsLocked) {
			return
		}

		if(this.state.views.length > 1) {
			this.viewOperationsLocked = true
			this.setState({ transition: 'popping' })
			setTimeout(() => {
				this.setState(prevState => ({
					transition: null,
					views: prevState.views.slice(0, prevState.views.length - 1)
				}))
				this.viewControllerRefs.splice(-1, 1)
				this.viewOperationsLocked = false
				if(onComplete) {
					onComplete()
				}
			}, NAVIGATION_TRANSITION_TIME)
		} else {
			if(onComplete) {
				onComplete()
			}
		}

	}

	popToRootView(onComplete) {
		
		if(this.viewOperationsLocked || this.state.views.length < 2) {
			if(onComplete) {
				onComplete()
			}
			return
		}

		if(this.state.views.length > 2) {
			this.viewOperationsLocked = true
			this.setState(prevState => ({
				transition: null,
				views: [...prevState.views.slice(0, 1), ...prevState.views.slice(-1)]
			}), _ => {
				this.viewOperationsLocked = false
				this.popView(onComplete)
			})
		} else {
			this.popView(onComplete)
		}

	}

	render() {
		let classes = [ 'NavigationController' ]
		if(this.state.transition) {
			classes.push(this.state.transition)
		}
		return (
			<div className={ classes.join(' ') } >
				{ this.state.views }
			</div>
		)

	}

}

Site.setupEvaluatorDependencies()

if(Uniwrapper.isWrapped) {
	Uniwrapper.getInstance().sendRequest('GET_ENVIRONMENT', {}, env => {
		API.shared.environment.native = env
		API.shared.readyToConnect()
	
		// Apply Safe Area Insets
		const insets = env.layout.safeAreaInsets
		let style = document.createElement('style')
		style.innerHTML = '.TopInset { height: ' + insets.top + 'px; } .BottomInset { height: ' + insets.bottom + 'px; }'
		const ref = document.querySelector('body')
		ref.append(style)

		ReactDOM.render(<AppWrap/>, document.getElementById('root'), () => {
			Uniwrapper.getInstance().initialRenderDone()
		})
	})
} else {
	API.shared.readyToConnect()
	ReactDOM.render(<AppWrap/>, document.getElementById('root'))
}

// Object.entries polyfil (from Mozilla Developer site)
if (!Object.entries) {
	Object.entries = function( obj ){
	  var ownProps = Object.keys( obj ),
		  i = ownProps.length,
		  resArray = new Array(i) // preallocate the Array
	  while (i--)
		resArray[i] = [ownProps[i], obj[ownProps[i]]]
	  
	  return resArray
	}
}