import React from 'react'

import ViewController from '../ViewController'
import ButtonItem from '../Items/ButtonItem'
import HeadingItem from '../Items/HeadingItem'

import API from '../api'

export default class SiteOptionsViewController extends ViewController {

	constructor(props) {
		super(props)
		this.navigationItem.title = 'Dashboard Options'
	}

	deleteDashboard() {
		API.shared.sendRequest('UNLINK', { id: this.props.site.id }, response => {
			this.props.navigationController.popToRootView()
		})
	}

	renderView() {
		return (
			<div className="Items">
				<HeadingItem config={ { icon: 'file', label: this.props.site.config.title }}/>
				<ButtonItem
					config= { {
						icon: 'trash',
						label: 'Delete Dashboard',
					} }
					onAction={ _ => this.deleteDashboard() }
				/>
			</div>
		)
	}

}
