import React from 'react';

import ButtonItem from './Items/ButtonItem';
import ToggleItem from './Items/ToggleItem';
import RowItem from './Items/RowItem';
import ButtonGroupItem from './Items/ButtonGroupItem';
import HeadingItem from './Items/HeadingItem';
import LabelItem from './Items/LabelItem';
import SliderItem from './Items/SliderItem';

export default class Renderer {

	static renderItem(item, idx, site) {
		const classMapping = {
			button: ButtonItem,
			row: RowItem,
			heading: HeadingItem,
			slider: SliderItem,
			label: LabelItem,
			'button-group': ButtonGroupItem,
			toggle: ToggleItem
		};
		const TargetClass = classMapping[item.type];
		return <TargetClass key={ idx } config={ item } site={ site }/>
	}

	static renderItems(items, site) {
		return (
			<>
				{ items.map((item, idx) => {
					return Renderer.renderItem(item, idx, site);
				})}
			</>
		);
	}

}
