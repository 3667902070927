import React from 'react';
import Item from '../Item';

export default class LabelItem extends Item {

	getAdditionalClasses() {
		return [ 'LabelItem' ];
	}

	performAction() {
		return;
	}

	renderItem(values) {
		return (
			<>
				<div className="Value">{ values.value }</div>
				<div className="Label">{ values.label }</div>
			</>
		);
	}

}
