import React from 'react';
import Item from '../Item';

export default class HeadingItem extends Item {

	getAdditionalClasses() {
		return [ 'HeadingItem' ];
	}

	performAction() {
		return;
	}

	renderItem(values) {
		return (
			<>
				{ values.icon && <i className={ "Icon fas fa-fw fa-" + values.icon }></i> }
				{ values.label }
				{ values.rightLabel && <div className="rightLabel">{ values.rightLabel }</div> }
			</>
		);
	}

}
