import React from 'react';

import Item from '../Item';

export default class ButtonItem extends Item {
	
	getAdditionalClasses(values) {
		let c = [ 'ButtonItem' ];
		if(values.highlighted) {
			c.push('highlighted');
		}
		if(values.disclosure) {
			c.push('withDisclosure');
		}
		return c;
	}

	performAction() {
		super.performAction();
		if(this.values.mode === 'toggle') {
			this.valueCache.highlighted = !this.valueCache.highlighted;
			this.forceUpdate();
		}
	}

	renderItem(values) {
		return (<>
			{ values.icon && <i className={ "Icon fas fa-fw fa-" + values.icon }/> }
			{ values.label && <div className="Label">{ values.label }</div> }
			{ values.disclosure && <i className="Disclosure fas fa-fw fa-angle-right"/> }
		</>);
	}

}
