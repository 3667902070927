import React from 'react'

import API from '../api'
import Site from '../Site'
import ViewController from '../ViewController'
import SiteViewController from './SiteViewController'
import CloudLinkViewController from './CloudLinkViewController';

export default class LibraryViewController extends ViewController {

	constructor(props) {
		super(props)
		this.navigationItem = null
	}

	componentDidMount() {
		API.shared.addEventListener(this, API.Events.DashboardListUpdated, () => {
			this.forceUpdate()
		})

		API.shared.addEventListener(this, API.Events.CloudConnectionStateChanged, () => {
			this.forceUpdate()
		})
	}

	componentWillUnmount() {
		API.shared.removeEventListener(this)
	}

	onDashboardSelect(dashboard) {
		let site = new Site(JSON.parse(dashboard.data))
		site.id = dashboard.id
		this.props.navigationController.pushView(<SiteViewController site={ site }/>)	
	}

	onCloudLinkButton() {
		this.props.navigationController.pushView(<CloudLinkViewController/>)	
	}

	getAdditionalClasses() {
		return [ 'LibraryViewController' ]
	}

	renderView() {

		const dashboards = API.shared.dashboards

		return (
			<>
				<div/>
				<div className="Intro">
					<i className={ "fas fa-fw fa-box-open" }/><br/>
					Dashboard Library
				</div>
				<div className="Items">
					{ dashboards.map(dashboard => {
						return (
							<SimpleButton
								key={ 'd' + dashboard.id }
								title={ dashboard.title }
								onClick={ _ => this.onDashboardSelect(dashboard) }
							/>
						)
					}) }
				</div>
				<div className="Status">
					{ API.shared.connected &&
						<div className="NewCloudLinkButton" onClick={ _ => this.onCloudLinkButton() }>
							<i class="Icon fas fa-fw fa-plus-square"/>
							Link Dashboard
						</div>
					}
					{ !API.shared.connected &&
						<div className="CloudUnreachable">
							<i class="Icon fas fa-fw fa-exclamation-triangle"/>
							Cloud unreachable!
						</div>
					}
					<div className="Version">
						{ API.shared.version } ({ API.shared.versionCode })
					</div>
				</div>
			</>
		)
	}

}

function SimpleButton(props) {
	return (
		<div class="Item ButtonItem withDisclosure" key={ props.key } onClick={ props.onClick }>
			<i class="Icon fas fa-fw fa-cube"/>
			<div class="Label">{ props.title }</div>
			<i class="Disclosure fas fa-fw fa-angle-right"/>
		</div>
	)
}