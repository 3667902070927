import React from 'react';

import Item from '../Item';

export default class ToggleItem extends Item {
	
	onTouchEnd(e) {
		if(this.state.pressed) {
			this.valueCache.on = !this.values.on;
		}
		super.onTouchEnd(e);
	}

	getAdditionalClasses(values) {
		let classes = [ 'ToggleItem' ];
		if(values.on) {
			classes.push('isOn');
		}
		return classes;
	}

	renderItem(values) {
		return (<>
			{ values.icon && <i className={ "Icon fas fa-fw fa-" + values.icon }/> }
			{ values.label && <div className="Label">{ values.label }</div> }
			<div className="Knob">
				<div className="KnobCenter"/>
			</div>
		</>);
	}

}
