import React, { Component } from 'react';

export default class ViewController extends Component {

	constructor(props) {
		super(props);
		this.state = {};
		this.navigationItem = {
			title: '...',
			rightButton: null
		};
	}

	getAdditionalClasses() {
		return [];
	}

	renderView() {
		return null;
	}

	onHeaderBackClick() {
		this.props.navigationController.popView();
	}

	renderHeaderButton(icon, className, onClick) {
		if(icon) {
			return (
				<div className={ className } onClick={ onClick }>
					<i className={ "fas fa-fw fa-" + icon }/>
				</div>
			);
		} else {
			return (
				<div className={ className }>
					<i className="fas fa-fw"/>
				</div>
			);
		}
	}

	renderHeaderContentView() {
		
		if(this.props.navigationController.viewControllerRefs.length > 1) {
			var leftButton = this.renderHeaderButton('angle-left', 'LeftButton', _ => this.onHeaderBackClick());
		} else {
			leftButton = this.renderHeaderButton(null, 'LeftButton', null);
		}

		if(this.navigationItem.rightButton) {
			var rightButton = this.renderHeaderButton(this.navigationItem.rightButton.icon, 'RightButton', this.navigationItem.rightButton.onClick);
		} else {
			rightButton = this.renderHeaderButton(null, 'RightButton', null);
		}

		return (
			<>
				{ leftButton }
				<div className="Title">{ this.navigationItem.title }</div>
				{ rightButton }
			</>
		);
	}

	render() {
		let classes = ['ViewController', 'drawsHeaderBackground', ...this.getAdditionalClasses()];
		return (
			<div className={ classes.join(' ') } ref="mainElement">
				<div className="Wrapper">
					{ this.navigationItem && <>
						<div className="Header">
							<div className="TopInset"/>
							<div className="ContentView">
								{ this.renderHeaderContentView() }
							</div>
						</div>
					</> }
					<div className="ContentViewWrapper">
						{ !this.navigationItem &&
							<div className="TopInset"/>
						}
						<div className="ContentView">
							{ this.renderView() }
						</div>
						<div className="BottomInset"/>
					</div>
				</div>
			</div>
		);
	}

}
