import Util from './Util';

import math from 'mathjs';
const vsprintf = require("sprintf-js").vsprintf;

export default class Site {

	constructor(config) {
		this.constants = config.constants;
		this.config = this.runPreProcessor(config);
		this.running = true;
		this.filePath = null;
		this.dataSources = {};
		this.busy = false;
		this.id = null;

		console.log('Site::constructor', { title: this.config.title });
	}

	static setupEvaluatorDependencies() {
		math.import({
			parseJSON: function(_json) {
				try {
					return JSON.parse(_json);
				} catch(e) {
					return null;
				}
			},
			printf: function(_format, ...rest) {
				return vsprintf(_format, rest);
			}
		});
	}

	runPreProcessor(obj) {
		if(typeof(obj) === 'string') {
			for(let k in this.constants) {
				// TODO: preapend $
				obj = obj.replace('$' + k, this.constants[k]);
			}
		} else if(typeof(obj) === 'object') {
			for(let k in obj) {
				if(k === 'constants') {
					continue;
				}
				obj[k] = this.runPreProcessor(obj[k]);
			}
		}
		return obj;
	}

	getDataSource(name) {
		if(!(name in this.dataSources)) {
			if(!(name in this.config['data-sources'])) {
				this.logicError('Data source "' + name + '" does not exist!');
				return null;
			}
			this.dataSources[name] = new DataSource(this, this.config['data-sources'][name], name);
		}
		return this.dataSources[name];
	}

	logicError(err) {
		if(typeof(err) === 'object') {
			err = err.message;
		}
		console.log('*** Logic Error', err);
	}

	evaluateExpression(aExpression, additionalContext, component) {
		console.log('Site::evaluateExpression', { aExpression, additionalContext });

		try {
			const parsed = math.parse(aExpression);
			console.log('parsed', parsed);

			let variables = [];
			parsed.traverse(function (node, path, parent) {
				if(node.type === 'SymbolNode') {
					if(variables.indexOf(node.name) === -1) {
						variables.push(node.name);
					}
				}
			});
			console.log('variables', variables);

			let partial = false;
			let ctx = (additionalContext ? additionalContext : {});
			for(let dsName of variables) {
				if(ctx.hasOwnProperty(dsName)) {
					continue;
				}
				let ds = this.getDataSource(dsName);
				ds.linkWithComponent(component);
				if(ds.data) {
					ctx[dsName] = ds.data;
				} else {
					partial = true;
				}
			}

			if(!partial) {
				const compiled = parsed.compile();
				console.log('compiled', compiled);

				const ret = compiled.eval(ctx);
				console.log('run', ret);
				return ret;
			}

		} catch(e) {
			this.logicError(e);
		}

		return null;
	}

	loadDataSources(dataSources, onComplete) {
		if(this.busy) {
			console.log('busy');
			return;
		}
		
		console.log('Site::loadDataSources()', dataSources);
		dataSources = (dataSources ? dataSources : Object.keys(this.dataSources));
		let waiting = dataSources.length;
		if(waiting === 0) {
			return;
		}
		
		this.busy = true;

		let after = _ => {
			waiting--;
			if(waiting === 0) {
				this.busy = false;
				if(onComplete) {
					onComplete();
				}
			}
		};

		for(let dsKey of dataSources) {
			this.getDataSource(dsKey).load(after);
		}
	}

	runHTTPRequestWithConfig(config, callback) {
		let opts = {};
		if(config.method) {
			opts.method = config.method;
		}
		if(config.headers) {
			opts.headers = config.headers;
		}
		if(config.arguments) {
			if(config.json) {
				Util.setJSONFetchOpts(opts, config.arguments);
			} else {
				Util.setFormFetchOpts(opts, config.arguments);
			}
		}
		Util.httpRequest(config.url, opts, response => {
			callback(response);
		});
	}

	deinitialize() {
		if(!this.running) {
			return;
		}
		this.running = false;
		for(let dsKey in this.dataSources) {
			this.dataSources[dsKey].deinitialize();
		}
		console.log('Site::deinitialize()', { title: this.config.title });
	}

}

class DataSource {

	constructor(site, config, name) {
		this.site = site;
		this.config = config;
		this.name = name;
		this.data = null;
		this.updateTimer = null;
		this.linkedComponents = [];
		this.busy = false;
		console.log('DataSource::constructor', { name, config });
	}

	deinitialize() {
		clearTimeout(this.updateTimer);
		this.updateTimer = null;
	}

	linkWithComponent(component) {
		if(this.linkedComponents.indexOf(component) === -1) {
			this.linkedComponents.push(component);
		}
	}

	load(onComplete) {
		if(this.busy) {
			return;
		}
		this.busy = true;

		console.log('DataSource(' + this.name + ')::load()');

		clearTimeout(this.updateTimer);
		this.updateTimer = null;

		this.site.runHTTPRequestWithConfig(this.config, response => {
			this.busy = false;
			response.json().then(parsedResponse => {
				this.data = parsedResponse;
				if(this.config['modifier=']) {
					this.data = this.site.evaluateExpression(this.config['modifier='], { self: this.data });
				}
				for(let component of this.linkedComponents) {
					component.dataSourceUpdated();
				}
				if(onComplete) {
					onComplete();
				}
				if(this.config.updateInterval) {
					if(this.config.updateInterval < 1) {
						this.config.updateInterval = 1;
					}
					clearTimeout(this.updateTimer);
					this.updateTimer = setTimeout(
						() => { this.load() },
						(this.config.updateInterval * 1000)
					);
				}
			});
		});
	}

}
