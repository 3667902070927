import ButtonItem from "./Items/ButtonItem";

class ButtonsCloud {
	
	constructor() {
		this.rqCallbacks = {}
		this.rqNextCallbackId = 1

		this.eventCallbacks = []

		this.connect()
		console.log('ButtonsCloud:constructor')
	}

	addEventListener(listener, event, callback) {
		this.eventCallbacks.push({ listener, event, callback })
	}

	removeEventListener(listener) {
		this.eventCallbacks = this.eventCallbacks.filter(ec => (ec.listener !== listener))
	}

	triggerEvent(event, data) {
		for(const ec of this.eventCallbacks) {
			if(ec.event == event) {
				ec.callback(data)
			}
		}
	}

	connect() {
		if(this.socket) {
			this.socket.close()
			this.socket.onopen = null
			this.socket.onmessage = null
			this.socket.onerror = null
			this.socket.onclose = null
			this.socket = null
		}
		this.connected = false
		let cloudAddress = 'wss://cloud.buttons.app'
		if(document.location.hostname === 'localhost') {
			cloudAddress = 'ws://localhost:11030'
		}
		this.socket = new WebSocket(cloudAddress)
		this.socket.onopen = _ => this.onConnect()
		this.socket.onmessage = event => this.onMessage(event)
		this.socket.onerror = event => this.onError(event)
		this.socket.onclose = event => this.onClose(event)
	}

	sendMessage(msg) {
		console.log('ButtonsCloud:sendMessage', msg)
		this.socket.send(JSON.stringify(msg))
	}

	sendRequest(rq, args, callback) {
		if(typeof(args) !== 'object') {
			args = {}
		}
		args = { rq, ...args }
		if(callback) {
			args.callbackId = this.rqNextCallbackId++
			this.rqCallbacks[args.callbackId] = callback
		}
		this.sendMessage(args)
	}

	onConnect() {
		console.log('ButtonsCloud:onConnect')
		if(this.reconnectTimer) {
			clearTimeout(this.reconnectTimer)
			this.reconnectTimer = null
		}
		this.connected = true
		this.sendMessage({ rq: 'INIT' })
		this.triggerEvent(ButtonsCloud.Events.Connected)
	}

	onMessage(event) {
		const obj = JSON.parse(event.data)
		console.log('ButtonsCloud:onMessage', obj)
		if(obj.rq == 'RESPONSE' && obj.callbackId && this.rqCallbacks[obj.callbackId]) {
			this.rqCallbacks[obj.callbackId](obj.response)
			delete this.rqCallbacks[obj.callbackId]
		}
		this.triggerEvent(ButtonsCloud.Events.MessageReceived, obj)
	}

	onError(event) {
		console.log('ButtonsCloud:onError')
		this.socket.close()
	}

	onClose(event) {
		if(this.connected) {
			console.log('ButtonsCloud:onClose')
			this.connected = false
			this.socket.close()
			this.triggerEvent(ButtonsCloud.Events.Disconnected)
		}
		if(this.reconnectTimer) {
			clearTimeout(this.reconnectTimer)
		}
		console.log('creating timer')
		this.reconnectTimer = setTimeout(() => {
			this.connect()
		}, 5000)
	}

}

ButtonsCloud.Events = {
	Connected: 1,
	MessageReceived: 2,
	Disconnected: 3
}

// ======================================================

export default class API {

	static get shared() {
		if(!API.sharedAPIInstance) {
			API.sharedAPIInstance = new API()
		}
		return API.sharedAPIInstance
	}
	
	constructor() {
		console.log('API:Constructor!')
		this.connected = false

		this.versionCode = 1
		this.version = '0.0.1'
		this.environment = {
			agent: {
				versionCode: this.versionCode,
				version: this.version,
			},
			browser: navigator.userAgent
		}

		this.dashboards = null
		
		let cachedDashboards = localStorage.getItem('dashboardCache')
		if(cachedDashboards) {
			try {
				cachedDashboards = JSON.parse(cachedDashboards)
				this.dashboards = cachedDashboards
			} catch(e) {
				this.dashboards = null
			}
		}

		if(!this.dashboards) {
			this.dashboards = []
			this.saveDashboardCache()
		}

		this.eventCallbacks = []		
	}

	readyToConnect() {
		if(this.cloud) {
			return
		}
		this.cloud = new ButtonsCloud()
		this.cloud.addEventListener(this, ButtonsCloud.Events.Connected, () => this.onCloudConnected())
		this.cloud.addEventListener(this, ButtonsCloud.Events.Disconnected, () => this.onCloudDisconnected())
		this.cloud.addEventListener(this, ButtonsCloud.Events.MessageReceived, msg => this.onCloudMessage(msg))
	}

	onCloudConnected() {
		const agentKey = localStorage.getItem('agent-key')
		if(!agentKey) {
			this.sendRequest('GEN-AGENT-KEY', {}, response => {
				localStorage.setItem('agent-key', response.key)
				this.cloudAuth()
			})
		} else {
			this.cloudAuth()
		}
	}

	onCloudDisconnected() {
		this.connected = false
		this.triggerEvent(API.Events.CloudConnectionStateChanged)
	}

	cloudAuth() {
		const agentKey = localStorage.getItem('agent-key')
		if(!agentKey) {
			return
		}
		this.sendRequest('INIT-AGENT', { key: agentKey, versionCode: this.versionCode, environment: this.environment }, response => {
			this.dashboards = response.dashboards
			this.saveDashboardCache()
			this.triggerEvent(API.Events.DashboardListUpdated)
			this.connected = true
			this.triggerEvent(API.Events.CloudConnectionStateChanged)
		})
	}

	saveDashboardCache() {
		localStorage.setItem('dashboardCache', JSON.stringify(this.dashboards))
	}

	onCloudMessage(msg) {
		if(msg.rq == 'DASHBOARD_EVENT') {
			const dashboard = msg.dashboard
			if(msg.type == 'CREATE') {
				this.dashboards.push(dashboard)
			} else if(msg.type == 'UPDATE') {
				for(const idx in this.dashboards) {
					if(this.dashboards[idx].id == dashboard.id) {
						for(var k in dashboard) {
							this.dashboards[idx][k] = dashboard[k]
						}
						break
					}
				}
			} else if(msg.type == 'DELETE') {
				for(const idx in this.dashboards) {
					if(this.dashboards[idx].id == dashboard.id) {
						this.dashboards.splice(idx, 1)
						break
					}
				}
			} else {
				console.log('unknown dashboard event', msg.type)
				return
			}
			this.saveDashboardCache()
			this.triggerEvent(API.Events.DashboardListUpdated)

			if(msg.type == 'CREATE') {
				this.triggerEvent(API.Events.NewLinkEstablished)
			}
		}
	}

	addEventListener(listener, event, callback) {
		this.eventCallbacks.push({ listener, event, callback })
	}

	removeEventListener(listener) {
		this.eventCallbacks = this.eventCallbacks.filter(ec => (ec.listener !== listener))
	}

	triggerEvent(event, data) {
		for(const ec of this.eventCallbacks) {
			if(ec.event == event) {
				ec.callback(data)
			}
		}
	}

	sendRequest(rq, args, callback) {
		return this.cloud.sendRequest(rq, args, callback)
	}

}

API.Events = {
	DashboardListUpdated: 1,
	NewLinkEstablished: 2,
	CloudConnectionStateChanged: 3
}
