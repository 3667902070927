import { fetch as fetchPolyfill } from 'whatwg-fetch'

export default class Util {

	static copyObject(obj) {
		// TODO: Needs something more efficent
		return JSON.parse(JSON.stringify(obj));
	}

	static setFormFetchOpts(opts, data) {
		if(!opts.headers) {
			opts.headers = {};
		}
		opts.headers['Content-Type'] = 'application/x-www-form-urlencoded';
		opts.body = Object.entries(data).map(kv => kv.map(encodeURIComponent).join("=")).join("&");
		return opts;
	}

	static setJSONFetchOpts(opts, data) {
		if(!opts.headers) {
			opts.headers = {};
		}
		opts.headers['Content-Type'] = 'application/json; charset=utf-8';
		opts.body = JSON.stringify(data);
		return opts;
	}

	static _httpRequest(url, opts, callback) {
		let fetcher = (window.fetch ? window.fetch : fetchPolyfill);
		fetcher(url, opts)
			.then(parsedResponse => {
				if(callback) {
					callback(parsedResponse);
				}
			}).catch(err => {});
	}

	static httpRequest(url, opts, callback) {
		console.log('Util.httpRequest', url);

		if(false /* slow network simulation */) {
			setTimeout(() => { Util._httpRequest(url, callback) }, 1000);
		} else {
			Util._httpRequest(url, opts, callback);
		}
	}

}
